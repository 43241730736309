.frame {
	display: flex;
	min-height: 100vh;
}

/* 左側フレーム部 */
.left_frame {
	width: 200px;
	min-height: 100vh;
	box-shadow: 2px 0 15px 0 rgba(0, 0, 0, 0.15), 2px 0 20px 0 rgba(0, 0, 0, 0.2);
	z-index: 1;
}

/* 右側フレーム部 */
.right_frame {
	width: 100%;
	min-height: 100vh;
	z-index: 0;
}
