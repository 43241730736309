.left_menu {
}

.left_header {
	width: 200px;
	height: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.main_nav {
	display: flex;
	align-items: center;
	margin-top: 20px;
}

.main_nav > a {
	width: 90%;
	display: flex;
	align-items: center;
	line-height: 2;
	margin-top: 2px;
	margin-bottom: 2px;
}

.logo_figure {
	width: 150px;
	height: 36px;
	background-size: contain;
	background-repeat: no-repeat;
	cursor: pointer;
}

.logo_figure::after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 30px;
	height: 1px;
	width: 140px;
	background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 20%, rgba(255, 255, 255, 0.5) 80%, rgba(255, 255, 255, 0) 100%);

}

.material-icons {
	margin-right: 5px;
}
