/* 右側ヘッダー部 */
.right_header {
	width: 100%;
	height: 50px;
	display: flex;
	align-items: center;
}

/* パンくずリスト */
.breadcrumb_list {
	padding-left: 30px;
}

.main_container {
	margin-top: 30px;
	padding-left: 1.5rem !important;
	font-size: 1rem;
}

.main_container p {
	font-size: 0.875rem;
}

.top_nav {
	margin-left: auto;
	margin-right: 20px;
}

.top_nav .dropdown-menu {
	background-color: white;
}

.login_user {
	font-size: 12px;
	color: #888;
}

.action_btn_group > :not(:first-of-type) {
	margin-left: 10px;
}

.info_link {
	display: flex;
	align-items: center;
	margin-left: 10px;
	text-decoration: none;
}

.info_icon {
	font-size: 1.2rem;
}

.modal_info_icon {
	margin-right: 5px;
}

.modal_action_btn_group {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.exec_order_badge {
	margin-right: 5px;
	font-weight: bold;
}

.wide_br {
	display: block;
	content: "";
	margin: 10px 0;
}

/* プログレストラッカー */
.progress_tracker {
	padding: 0;
	display: flex;
	align-items: center;
	color: #8e5800;
	max-width: 100%;
	flex-wrap: wrap;
	font-weight: 400;
	background-color: transparent !important;
	border: 0 !important;
}

.progress_tracker div {
	padding: 0.7rem 2rem;
	margin-bottom: 8px;
	position: relative;
	background-color: #f9dfb3;
	float: left;
}

.progress_tracker div:first-child {
	padding-left: 1.5rem;
}

.progress_tracker div:last-child {
	padding-right: 1.5rem;
}

.progress_tracker div.active {
	color: #fff;
	background-color: #ef9300;
}

.progress_tracker div.active:not(:first-child)::before {
	content: "";
	position: absolute;
	top: 0;
	left: -12px;
	width: 12px;
	background:linear-gradient(to bottom left, #fff 50%, transparent 50%) top left/ 100% 50% no-repeat,
	linear-gradient(to top left, #fff 50%, transparent 50%) bottom left / 100% 50% no-repeat;
	height: 100%;
}

.progress_tracker div:not(.active):not(:first-child)::before {
	content: "";
	position: absolute;
	top: 0;
	left: -12px;
	width: 12px;
	background:linear-gradient(to bottom left, #fff 50%, transparent 50%) top left/ 100% 50% no-repeat,
	linear-gradient(to top left, #fff 50%, transparent 50%) bottom left / 100% 50% no-repeat;
	height: 100%;
	z-index: 1;
}

.progress_tracker div::after {
	content: "";
	position: absolute;
	top: 0;
	right: -12px;
	width: 12px;
	background: linear-gradient(to bottom left, transparent 50%, #ef9300 50%) top left/ 100% 50% no-repeat,
	linear-gradient(to top left, transparent 50%, #ef9300 50%) bottom left / 100% 50% no-repeat;
	height: 100%;
}

.progress_tracker div:not(.active)::after {
	content: "";
	position: absolute;
	top: 0;
	right: -12px;
	width: 12px;
	background: linear-gradient(to bottom left, transparent 50%, #fff 50%) top left/ 100% 50% no-repeat,
	linear-gradient(to top left, transparent 50%, #fff 50%) bottom left / 100% 50% no-repeat;
	height: 100%;
	z-index: 1;
}

.success_icon {
	color: #ef9300;
	font-size: 150px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.no_border_button {
	border: none;
	outline: none;
	background: transparent;
}

/* 検索フォームクリアボタン */
.search_clear_button {
	position: absolute;
	right: 5px;
	top: 50%;
	transform: translateY(-50%);
	transition-duration: 0.15s;
	cursor: pointer;
	color: rgba(0, 0, 0, 0.25);
	z-index: 100;
	line-height: 1.5;
}

.search_clear_button:hover {
	color: rgba(0, 0, 0, 0.5);
}

/* リンク(背景色変更) */
a.bg_link {
	color: #b48246;
}

a.bg_link:hover > div {
	background-color: rgba(0, 0, 0, 0.05);
}

/* ハイライト(Alert) */
.alert_highlight {
	color: #f04124 !important;
	background-color: #ffeae6 !important;
	border-color: #fbc6bd !important;
}

/* ハイライト(テキスト) */
.text_highlight {
	color: #f04124;
	background-color: #ffeae6;
	border: 1px solid #fbc6bd;
	padding: 0.1rem 0.25rem;
	font-weight: 300;
}
