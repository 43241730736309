/* Bootstrapのカスタムデザイン */

figure {
	margin: 0;
}

th {
	color: #e7e6e6;
	background-color: #666666 !important;
}

.bg-primary {
	background-color: #666666 !important;
}

.bg-secondary {
	background-color: #b48246 !important;
}

.badge.bg-secondary {
	color: #fff !important;
}

.btn-primary {
	color: #e7e6e6;
	background-color: #b48246;
	border-color: #b48246;
}

.btn-primary:hover {
	color: #e7e6e6;
	background-color: #b46d2a;
	border-color: #b46621;
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
	color: #e7e6e6;
	background-color: #b46d2a;
	border-color: #b46621;
	box-shadow: 0 0 0 0.25rem rgba(180, 109, 42, 0.25);
}

.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
.show > .btn-primary.dropdown-toggle {
	color: #e7e6e6;
	background-color: #b46621;
	border-color: #b45e18;
}

.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.25rem rgba(180, 109, 42, 0.25);
}

.btn-primary:disabled, .btn-primary.disabled {
	color: #e7e6e6;
	background-color: #b48246;
	border-color: #b48246;
}

.btn-outline-primary {
	color: #b48246;
	border-color: #b48246;
}

.btn-outline-primary:hover {
	color: #e7e6e6;
	background-color: #b48246;
	border-color: #b48246;
}

.btn-outline-primary:focus {
	color: #e7e6e6;
	background-color: #b48246;
	border-color: #b48246;
}

.text-primary {
  color: #b48246 !important;
}

a {
	color: #b48246;
	text-decoration: underline;
}

a:hover {
	color: #b46621;
}

.card {
	margin: 1rem;
}

.card-header {
	color: #e7e6e6;
}

.left_menu .nav-link,
.left_menu .nav-link:visited,
.left_menu .nav-link:active {
	color: rgba( 231, 230, 230, 0.8 );
	text-decoration: none;
}

.left_menu .nav-link:hover {
	color: white;
	background-color: rgba(255, 255, 255, 0.1);
}

.left_menu .nav-link.active,
.left_menu .nav-link.active:visited,
.left_menu .nav-link.active:active {
	color: #666666 !important;
	background-color: rgba( 231, 230, 230, 0.8 ) !important;
}

.left_menu .nav-link.active:hover {
	color: white;
	background-color: rgba(255, 255, 255, 0.1);
}

.left_menu .nav-link.disabled {
	color: rgba(255, 255, 255, 0.4);
}

.nav-link {
	display: block;
	padding: 0.5rem 1rem;
	color: #b48246;
	text-decoration: none;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.nav-link:hover, .nav-link:focus {
  color: #b46621;
}

.nav-pills .active {
	border: 1px solid #665853;
}

.breadcrumb {
	border: none;
	padding: 0;
	margin: 0;
}

.btn {
	padding: 0.375rem 2.5rem;
}

.modal-content {
	border: none;
}

.modal-header {
	padding: 0.3rem 1rem;
}

.modal-title {
	display: flex;
	align-items: center;
}

.modal-body {
	font-size: 0.9rem;
}

.badge {
	padding: 0.35em 0.5rem;
}

abbr[title], abbr[data-bs-original-title] {
	text-decoration: none;
	cursor: default;
}

.btn {
	white-space: nowrap;
}

.page-link:hover {
	z-index: 2;
	color: #b48246;
	background-color: #eee;
	border-color: #dee2e6;
}

.page-link:focus {
	z-index: 3;
	color: #b46d2a;
	background-color: #eee;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(180, 109, 42, 0.25);
}

.page-item:not(:first-child) .page-link {
	margin-left: -1px;
}

.page-item.active .page-link {
	z-index: 3;
	color: #fff;
	background-color: #b46621;
	border-color: #b45e18;
}

.form-control-plaintext {
	display: block;
	width: 100%;
	padding: 0.375rem 0.75rem !important;
	margin-bottom: 0;
	line-height: 1.5;
	color: #222;
	background-color: transparent;
	border: solid transparent;
	border-width: 1px 0;
}

.form-control-plaintext:focus {
	border-color: transparent !important;
	outline: 0;
	box-shadow: none !important;
}

.bg-light .dropdown-menu .dropdown-item.active,
.bg-light .dropdown-menu .dropdown-item:hover,
.bg-light .dropdown-menu .dropdown-item:focus {
	background-color: rgba(0, 0, 0, 0.05);
	color: rgba(0, 0, 0, 0.7);
}
