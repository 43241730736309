/* 曜日表示 */
.fc .fc-col-header-cell-cushion {
	text-decoration: none;		/* <a>タグのアンダーバー非表示 */
}

/* 日付表示 */
.fc .fc-daygrid-day-top {
	justify-content: center;		/* テキストの左右センタリング */
	text-align: center;		/* テキストの左右センタリング */
}
.fc .fc-daygrid-day-number {
	text-decoration: none;		/* <a>タグのアンダーバー非表示 */
}

/* 日付表示 */
th.fc-day {
	background-color: #f3f3f3 !important;
	color: #222;
	font-weight: normal;
}

/* (日曜)日付表示 */
th.fc-day-sun {
	background-color: #fad4d4 !important;
	outline: 1px solid var( --fc-border-color, #ddd );		/* 内側罫線が表示されない為、設定 */
}
td.fc-day-sun {
	background-color: #ffeaea !important;
	outline: 1px solid var( --fc-border-color, #ddd );		/* 内側罫線が表示されない為、設定 */
}
/* (土曜)日付表示 */
th.fc-day-sat {
	background-color: #d1e2f4 !important;
	outline: 1px solid var( --fc-border-color, #ddd );		/* 内側罫線が表示されない為、設定 */
}
td.fc-day-sat {
	background-color: #eaf4ff !important;
	outline: 1px solid var( --fc-border-color, #ddd );		/* 内側罫線が表示されない為、設定 */
}

/* 前ボタン */
.bi-chevron-left:before {
	font-family: 'fcicons';
	speak: none;
	font-style: normal;
	font-weight: 700;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	content: "\e900";
}
.fc-prev-button {
	color: #b48246 !important;
	background-color: #fff !important;
	border: 1px solid transparent !important;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	padding: 0.375rem 1.5rem !important;
}
.fc-prev-button:hover {
	color: #b46621 !important;
	background-color: #eee !important;
	border-color: #dee2e6;
}
.fc-prev-button:focus {
	color: #b46621 !important;
	background-color: #eee !important;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(180, 109, 42, 0.25) !important;
}

/* 次ボタン */
.bi-chevron-right:before {
	font-family: 'fcicons';
	speak: none;
	font-style: normal;
	font-weight: 700;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	content: "\e901";
}
.fc-next-button {
	color: #b48246 !important;
	background-color: #fff !important;
	border: 1px solid transparent !important;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	padding: 0.375rem 1.5rem !important;
}
.fc-next-button:hover {
	color: #b46621 !important;
	background-color: #eee !important;
	border-color: #dee2e6;
}
.fc-next-button:focus {
	color: #b46621 !important;
	background-color: #eee !important;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(180, 109, 42, 0.25) !important;
}

/* イベントタイトル */
.fc-daygrid-block-event .fc-event-title {
	padding: 1px 1px 1px 5px !important;
}

/* ツールバータイトル */
.fc .fc-toolbar-title {
	font-size: 1.5rem !important;
	font-family: "Google Sans", Roboto, Arial, sans-serif !important;
}

.fc table {
	font-size: 0.875rem !important;
}
