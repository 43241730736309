.maintenance_container {
	height: 100vh;
	background-color: #eeebe6;
}

.maintenance_logo {
	position: relative;
}

.maintenance_logo_figure {
	width: 180px;
	height: 43px;
	background-size: contain;
	background-repeat: no-repeat;
	margin: 0 auto;
}

.maintenance_logo_figure::after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	height: 1px;
	width: 100%;
	background: linear-gradient(to right, rgba(180, 130, 70, 0) 0%, rgba(180, 130, 70, 0.5) 20%, rgba(180, 130, 70, 0.5) 80%, rgba(180, 130, 70, 0) 100%);
}
